import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby'; 
import Nav from 'react-bootstrap/Nav'; 
import Navbar from 'react-bootstrap/Navbar'; 
import NavDropdown from 'react-bootstrap/NavDropdown'; 

const getTourMenuItems = graphql `
{
    tours:allWordpressWpTour{
      edges{
        node{
          id
          title
          slug 
        }
      }
    } 
  } 
`;
const Navigation = () => {
    const { tours } = useStaticQuery(getTourMenuItems);
     
    return (
        <>
            <Navbar.Toggle aria-controls="basic-navbar-nav"></Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="justify-content-end flex-grow-1">
                    <Nav.Item>
                        <Link to="/" activeClassName="active" className="nav-link">Home</Link>
                    </Nav.Item> 
                    <NavDropdown title="Tour Packages" id="tour-packages"> 
                        {
                            tours.edges.map(({node}) =>  ( 
                                    <Link 
                                        activeClassName="active" 
                                        className="dropdown-item"  
                                        key={node.id}
                                        to={`/tours/${node.slug}`} 
                                        dangerouslySetInnerHTML={{__html: node.title}}></Link> 
                                )
                            )
                        }   
                    </NavDropdown>  
                    <Nav.Item>
                        <Link to="/photo-gallery" activeClassName="active" className="nav-link">Photo Gallery</Link>
                    </Nav.Item> 
                    <Nav.Item>
                        <Link to="/tour-guide" activeClassName="active" className="nav-link">Tour Guide</Link>
                    </Nav.Item>  
                    <Nav.Item>
                        <Link to="/contact-us" activeClassName="active" className="nav-link">Contact Us</Link>
                    </Nav.Item> 
                </Nav> 
            </Navbar.Collapse>
        </>
    );
}

export default Navigation;
