import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Image from 'gatsby-image'; 
import { FaFacebookF, FaTwitter,  FaInstagram} from 'react-icons/fa';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './footer.module.css';
const getFooterData = graphql `
{
  footerImage:file(relativePath:{eq:"footerImage.png"}){
    id
    childImageSharp{
      fluid(quality: 100){
        ...GatsbyImageSharpFluid_withWebp
      }
    } 
  }
  allTours:allWordpressWpTour{
      edges{
        node{
          id
          title
          slug 
        }
      }
    }
}
`;

const Footer = () => {
    const {footerImage:{childImageSharp:{fluid:mainFooterImage}}, allTours:{edges:tours}} = useStaticQuery(getFooterData);
 
    return (
        <footer className={styles.Footer}>
            <Image fluid={mainFooterImage}/>
            <Container>
                <Row>
                    <Col md={4} sm={12}>
                        <h4 className={styles.widgetTitle}>Need Help?</h4>
                        <ul className={`${styles.widgetInfo} ${styles.widgetInfoContact} mt-3`}>
                            <li> 
                                <a href="tel://+918890067412">
                                    <i className={`icon_set_1_icon-89 ${styles.conIcon}`}></i>
                                     +91-8890067412
                                </a>
                            </li>
                            <li> 
                                <a href="mailto://connect@jaisalmerdesertsafari.in">
                                <i className={`icon_set_1_icon-84 pt-3 ${styles.conIcon}`}></i> 
                                    connect@jaisalmerdesertsafari.in
                                </a>
                            </li>
                        </ul>
                    </Col>
                    <Col md={2} sm={12} className="mt-md-0 mt-sm-3">
                        <h4 className={styles.widgetTitle}>About</h4>
                        <ul className={styles.widgetInfo}>
                            <li>  
                                <Link to="/tours">Tour Packages</Link>
                            </li> 
                            <li>  
                                <Link to="/photo-gallery">Photo Gallery</Link>
                            </li>
                            <li>  
                                <Link to="/tour-guide">Tour Guide</Link>
                            </li>
                            <li>  
                                <Link to="/contact-us">Help & Ask?</Link>
                            </li> 
                        </ul>
                    </Col>
                    <Col md={3} xs={12} className="mt-md-0 mt-sm-3"> 
                        <h4 className={styles.widgetTitle}>Discover</h4>
                        <ul className={styles.widgetInfo}>
                            {
                                tours.map(({node: tour}) => (
                                    <li key={tour.id}>  
                                        <Link to={`/tours/${tour.slug}`} dangerouslySetInnerHTML={{__html:tour.title}}></Link>
                                    </li> 
                                ))
                            }
                             
                        </ul>
                    </Col>
                    <Col md={3} xs={12} className="mt-md-0 mt-sm-3">
                        <h4 className={styles.widgetTitle}>Address</h4>
                        <div className={styles.widgetInfo}>
                            <p>
                                Sam Sand Dunes, Jaisalmer - Sam - Dhanana Rd, Jaisalmer, Rajasthan 345001
                            </p>
                             
                        </div>
                    </Col>
                </Row>
                <hr style={{backgroundColor:'rgba(256,256,256,0.3)'}}/>
                <Row>
                    <Col className="text-center">
                         <a href="https://fb.com" target="_blank" className={styles.socialLink} rel="noopener noreferrer">
                            <FaFacebookF/>
                         </a>
                         <a href="https://twitter.com" target="_blank" className={styles.socialLink} rel="noopener noreferrer">
                            <FaTwitter/>
                         </a>
                         <a href="https://instagram.com" target="_blank" className={styles.socialLink} rel="noopener noreferrer">
                            <FaInstagram/>
                         </a>
                    </Col>
                </Row>
                <Row>
                    <Col className={styles.copyright}>
                        <p className="mb-0 mt-3">{ new Date().getFullYear() } © Jaisalmer Desert Safart | Managed By Sun Rise Desert Resort</p>
                        <p>Design & Developed By <a href="https://zlines.in" target="_blank" rel="noopener noreferrer">Zlines Solution</a></p>
                    </Col>
                </Row>
            </Container>
            
        </footer>
    );
}

export default Footer;
