import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import Navbar from 'react-bootstrap/Navbar'; 
import Container from 'react-bootstrap/Container'; 
import { Helmet } from 'react-helmet';
import Navigation from './navigation';
import Topbar from './topBar';
import Footer from './footer';
import logo from '../../images/logo.png';
import logoLight from '../../images/logo-light.png';
import './layout.scss';
const Index = ({children, title, description}) => {
    const [isScrolled, setIsScrolled] = useState(false);
    const steadyTitle = "Jaisalmer Desert Safari";
    const pageTitle = title ? title + " | " + steadyTitle  : steadyTitle;
    const pageDescription = description ? description : '';
    useEffect(() => {
        window.onscroll = () => {
            const yScroll = window.scrollY;
            if(yScroll> 100){
                setIsScrolled(true);
            }else{
                setIsScrolled(false);
            }
        }
    });

    const toolbar = !isScrolled ? (<Container><Topbar/></Container>) : '';
    return (
        <>  
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{ pageTitle }</title>
                <meta name="description" content={pageDescription}/>
            </Helmet>
            <Navbar 
                className={`${isScrolled ? 'NavbarFixed' : 'Navbar'} flex-column`} 
                expand="lg" 
                variant={ isScrolled ? 'light' : 'dark' }
                fixed="top">
                {toolbar}
               
                <Container className={`${!isScrolled ? 'mt-3 pb-2' : ''}`}> 
                    <Navbar.Brand>
                        <Link to="/">
                            <img src={isScrolled ? logoLight : logo} alt="Jaisalmer Desert Safari"/>
                        </Link> 
                    </Navbar.Brand>
                    <Navigation/>
                </Container> 
            </Navbar> 
            <main>{children}</main>
            <Footer/>
       </>
    );
};

export default Index;
