import React from 'react';
import { Link } from 'gatsby';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'; 

const Topbar = () => {
    return (
        <Row className="flex-grow-1">
            <Col>
                <a href="tel://0045043204434" className='ToolbarLink'>Call +91-9024812494</a>
            </Col>
            <Col className="text-right">
                <Link to="/track" className='ToolbarLink'><i className="icon-download"></i> Booking Recipt</Link>
            </Col>
            <hr style={{margin:0,width:'100%',background:'rgba(255, 255, 255, 0.5)', position:'absolute', top:'39px', left:'0'}}/>
        </Row>
    )
};

export default Topbar;
